.hello_hide {
    font-weight: 500 !important;
    font-size: 19px !important;
}
@media (max-width: 520px) {
    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
     
    }
.css-1wrgmsj-MuiGrid-root {
    width: 92% !important;
}
    .file_btn {
        margin-top: 16px;
       
    }
    .hello_hide{
        display: none;
    }
  .logo_center {
      display: flex;
      justify-content: center;
     
      width: 100%;
   
      order: 1;
   
  }
.logo_center img {
    margin-right: 10px;
  
}
  .logout_container {
      justify-content: center;
   
      width: 100%;
     
      order: 2;
      
  }

  .logout_button {
      width: 100%;
      
      margin-top: 16px;
     
  }
}